<script setup>
import { useMainStore } from "@/store";
import Logo from "@/components/layout/Other/Header/Logo.vue";

const store = useMainStore();
</script>

<template>
  <div v-if="store.mainLoaderActive || !store.windowLoaded" class="mainLoader">
    <div
      class="mainLoader__line"
      :style="{
        width: store.mainLoaderActiveClass ? '100%' : 0,
        transition: store.mainLoaderActiveClass ? 'width 2s linear' : 'none',
      }"
    ></div>
    <div class="mainLoader__container">
      <section class="mainLoader__logo">
        <Logo />
      </section>
      <section class="mainLoader__title">
        <p class="mainLoader__title_large">
          {{ $t("Golden_Suisse") }}<span>®</span>
        </p>
        <div class="mainLoader__title_logo">{{ $t("Wealth_Protection") }}</div>
      </section>
    </div>

    <section class="mainLoader__subtitle" data-anim="fadeIn">{{ $t("Loading_Content") }}</section>
  </div>
</template>

<style lang="scss" scoped>
.mainLoader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: ". logo logo logo . subtitle subtitle subtitle subtitle .";
  grid-template-columns: 3.75% repeat(8, 11.5625%) 3.75%;
  padding: 30px 0 0 0;

  z-index: 1000;
  background: #101010;

  &__container {
    grid-area: logo;
    display: inline-flex;
  }

  &__logo {
    color: #fff;
    height: inherit;
    cursor: pointer;
  }

  &__title {
    height: inherit;
    cursor: pointer;
    width: fit-content;
    opacity: 0;
    color: #fff;
    &_large {
      font-size: 32px;
      font-weight: bold;
      letter-spacing: -0.07em;
      position: relative;
      span {
        font-size: 10px;
        position: absolute;
        top: 0;
        right: -10px;
      }
    }
    &_logo {
      color: #a0a0a0;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      line-height: 143%;
      letter-spacing: -0.02em;
      margin-top: 10px;
      font-size: 16px;
    }
  }

  &__subtitle {
    grid-area: subtitle;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.02em;
    color: #fff;
    opacity: 0,
  }

  &__text {
    font-size: 1.2rem;
    position: fixed !important;
    top: 1rem;
    left: 2.5rem;
    color: #fff;
    @include adaptive(mob) {
      left: 1.3rem;
      font-size: 1.5rem;
    }
    @include adaptive(tablet) {
      font-size: 2.2rem;
      margin-bottom: -3px;
      left: 1.5rem;
    }
  }
  &__line {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 2px;
    background: #fff;
    width: 0%;
  }
}

@media (max-width: 1023px) {
  .mainLoader {
    font-size: 10px;
    line-height: 140%;

    &__title {
      display: none;
    }

    &__logo {
      display: none;
    }
  }
}
</style>